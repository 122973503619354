import React from "react";
import AnimatedCall from "./AnimatedCall";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

const Footer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const services = [
    {
      id: 1,
      title: "service1title",
      description: "service1desp",
      image: "images/practice/Abu-dhabi-police.jpg",
    },
    {
      id: 2,
      title: "service2title",
      description: "service2desp",
      image: "images/practice/judicial-department.jpg",
    },
    {
      id: 3,
      title: "service3title",
      description: "service3desp",
      image: "images/practice/economic-development.jpg",
    },
    {
      id: 4,
      title: "service4title",
      description: "service4desp",
      image: "images/practice/translation.jpg",
    },
    {
      id: 5,
      title: "service5title",
      description: "service5desp",
      image: "images/practice/vehical-insurance.jpg",
    },
  ];
  return (
    <>
      <footer class="footer-part">
        <div class="footer-widget">
          <div class="container">
            <div class="row">
              <div class="col-12 col-sm-6 col-lg-4">
                <div class="footer-logo">
                  <a href="#">
                    <img src="images/Footer-logo.png" alt="" />
                  </a>
                  <p>{t("about-p1")}</p>
                  <ol class="flat-list">
                    <li>
                      <a href="#">
                        <i class="fa fa-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i class="fa fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i class="fa fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i class="fa fa-linkedin"></i>
                      </a>
                    </li>
                  </ol>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-lg-4 mt-4 mt-sm-0">
                <div class="footer-widget-item">
                  <h3>{t("service_areas")}</h3>
                  <ul class="footer-widget-link">
                    {services.map((serv) => (
                      <li style={{ cursor: "pointer" }}>
                        <span
                          onClick={() => {
                            navigate("/servicedetail", {
                              state: { serv },
                            });
                          }}
                        >
                          <i class="fa fa-angle-double-right"></i>
                          {t(serv.title)}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div class="col-12 col-sm-6 col-lg-4 mt-4 mt-lg-0">
                <div class="footer-widget-item">
                  <h3>{t("open_time")}</h3>
                  <ul class="footer-widget-office-time">
                    <li>
                      <p>{t("working_hours")}:</p>
                      <p>{t("mon_sat")}: 8am to 9pm</p>
                    </li>
                    {/* <li>
                      <p>Ramadan Working Hours:</p>
                      <p>Monday - Saturday:</p>
                      <p className="bold">
                        {" "}
                        ( 9am to 5pm ) - ( 9pm to 12:30pm )
                      </p>
                    </li> */}
                    <li>
                      <p>Vacation:</p>
                      <p>All Sunday</p>
                      <p>All Vacation Holiday</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="footer-copyright">
          <div class="container">
            <div class="row">
              <div class="col-xl-12">
                <p>
                  Copyright ©<span> 2024</span> | All rights reserved{" "}
                  <a href="/">Tarsh</a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <AnimatedCall></AnimatedCall>
        <div className="stickyPhone" onClick={() => {}}>
          <a
            href="https://api.whatsapp.com/send?phone=0263194460"
            target="_blank"
          >
            <img
              src="images/whatsapp.svg"
              alt="images description"
              width="60"
              className="img-fluid"
            />
          </a>
        </div>
      </footer>
    </>
  );
};
export default Footer;
