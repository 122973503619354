import React from "react";
import contact from "../constants/contact";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function ServiceDetail() {
  const location = useLocation();
  const { serv } = location.state || {}; // Destructure serv from location.state
  const { t, i18n } = useTranslation();
  console.log(serv); // Check if serv is defined
  React.useEffect(() => {
    // Scroll to the top of the page with smooth animation
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // Add smooth behavior
      });
    };

    // Call the scrollToTop function when the component gains focus
    scrollToTop();
  }, [serv]);
  return (
    <>
      <section className="breadcrumb-part" style={{ paddingTop: "150px" }}>
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <div className="breadcrumb-title">
                <h1>SERVICE DETAILS</h1>
              </div>
            </div>
          </div>
          <div className="breadcrumb-link">
            <ul className="flat-list">
              <li>
                <a href="index-2.html">Home</a>
              </li>
              <li>
                <a href="#">SERVICE AREA</a>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="section-p">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <div className="section-head-3-1">
                <h2>{t(serv.title)}</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-xl-4">
              <div className="practice-sidebar">
                <div className="sin-sidebar">
                  <div className="wid-heading">
                    <p>Contact our Info</p>
                  </div>
                  <div className="address-widget">
                    <p>Contact us about this service at</p>
                    <h6>
                      <i className="fa fa-map-marker" aria-hidden="true"></i>
                      address
                    </h6>
                    <span>{contact.location}</span>
                    <span>United Arab Emirates</span>
                    <h6>
                      <i className="fa fa-clock-o" aria-hidden="true"></i>
                      WORKING HOURS
                    </h6>
                    <span>Week Days: 11:00 - 20:00</span>
                    <span>Saturday: 11:00 - 18:00</span>
                    <span>Sunday: Closed</span>
                    <h6 className="sb-phone">
                      <i className="fa fa-phone" aria-hidden="true"></i>PHONE{" "}
                      <span>
                        {contact.phone} / {contact.phone2}
                      </span>
                    </h6>
                    <h6 className="sb-email">
                      <i className="fa fa-envelope" aria-hidden="true"></i>EMAIL
                      <span>
                        <a
                          href="https://themeim.com/cdn-cgi/l/email-protection"
                          className="__cf_email__"
                          data-cfemail="cdbeb8bdbda2bfb98da8b5aca0bda1a8e3aea2a0"
                        >
                          {contact.email}
                        </a>
                      </span>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-xl-8">
              <div className="prac-detils-area">
                <div className="prac-det-carousel">
                  <div
                    className="practice-slider swiper-container"
                    data-swiper-config='{"loop": true, "effect": "slide", "speed": 800, "autoplay": 5000, "paginationClickable": true }'
                  >
                    <div className="swiper-wrapper">
                      <div className="swiper-slide">
                        <img src={serv.image} alt="" />
                      </div>
                    </div>

                    <div className="swiper-pagination"></div>
                  </div>
                </div>
                <div className="prac-det-inner">
                  <p>{t(serv.description)}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
