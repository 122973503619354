const services = [
  {
    id: 1,
    title: "service1title",
    description: "service1desp",
    image: "images/practice/Abu-dhabi-police.jpg",
  },
  {
    id: 2,
    title: "service2title",
    description: "service2desp",
    image: "images/practice/judicial-department.jpg",
  },
  {
    id: 3,
    title: "service3title",
    description: "service3desp",
    image: "images/practice/economic-development.jpg",
  },
  {
    id: 4,
    title: "service4title",
    description: "service4desp",
    image: "images/practice/translation.jpg",
  },
  {
    id: 5,
    title: "service5title",
    description: "service5desp",
    image: "images/practice/vehical-insurance.jpg",
  },
  {
    id: 6,
    title: "service6title",
    description: "service6desp",
    image: "images/practice/health-insurance.jpg",
  },
  {
    id: 7,
    title: "service7title",
    description: "service7desp",
    image: "images/practice/transport-department.jpg",
  },
  {
    id: 8,
    title: "service8title",
    description: "service8desp",
    image: "images/practice/Municipality.jpg",
  },
  {
    id: 9,
    title: "service9title",
    description: "service9desp",
    image: "images/practice/Dubai-police.jpg",
  },
  {
    id: 10,
    title: "service10title",
    description: "service10desp",
    image: "images/practice/Traffic-system.jpg",
  },
  {
    id: 11,
    title: "service11title",
    description: "service11desp",
    image: "images/practice/passport.jpg",
  },
  {
    id: 12,
    title: "service12title",
    description: "service12desp",
    image: "images/practice/Ministry-HR.jpg",
  },
  {
    id: 13,
    title: "service13title",
    description: "service13desp",
    image: "images/practice/Tourist-visa.jpg",
  },
  {
    id: 14,
    title: "service14title",
    description: "service14desp",
    image: "images/practice/worker.jpg",
  },
];
export default services;
