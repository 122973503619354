import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Home from "./pages/Home";
import React from "react";
import About from "./pages/About";
import Services from "./pages/Services";
import Contact from "./pages/Contact";
import ServiceDetail from "./pages/ServiceDetail";
import "../src/constants/i18n";
import ServicesCompany from "./pages/ServicesCompany";

const App = () => {
  return (
    <>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/servicescompany" element={<ServicesCompany />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/servicedetail" element={<ServiceDetail />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
};
export default App;
