import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import services from "../constants/servicesind";
import AOS from "aos";
import "aos/dist/aos.css";

export default function Services() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  useEffect(() => {
    AOS.init();
    AOS.refresh();
    // Scroll to the top of the page on component mount
    window.scrollTo({ top: 0, behavior: "smooth" });

    // Optionally, add a listener for 'beforeunload' to scroll to top when the page is refreshed
    const onBeforeUnload = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };

    window.addEventListener("beforeunload", onBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, []);
  return (
    <>
      <section
        className="breadcrumb-part"
        style={{
          backgroundImage: "url(images/bg/breadcrumb-services-bg.jpg)",
          paddingTop: "150px",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <div className="breadcrumb-title">
                <h1>To Individual</h1>
              </div>
            </div>
          </div>
          <div className="breadcrumb-link">
            <ul className="flat-list">
              <li>
                <a href="#">Home</a>
              </li>
              <li>
                <a href="#"> To Individual</a>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="practise-4-part section-p">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <div className="section-head">
                <h2>Our Service</h2>
                <p>
                  Dummy text of the printing and typesetting industry. Lorem
                  Ipsum has been the industry's standard unknown printer took a
                  quis nostrud exercitatliquip.
                </p>
              </div>
            </div>
          </div>
          <div className="row" style={{ display: "flex", flexWrap: "wrap" }}>
            {services.map((serv) => (
              <div
                data-aos="fade-up"
                key={serv.id}
                className="col-sm-12 col-lg-4"
                style={{
                  display: "flex",
                  marginTop: 10,
                  // width: "calc(100% / 3)",
                }} // Adjust the width as per your layout requirements
              >
                <div
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    width: "100%",
                  }}
                  className="sin-practice-4"
                  onClick={() => {
                    navigate("/servicedetail", {
                      state: { serv },
                    });
                  }}
                >
                  <img
                    src={serv.image}
                    alt=""
                    style={{
                      flex: "0 0 auto",
                      height: "200px",
                      width: "100%",
                      objectFit: "cover",
                    }}
                  />
                  <span style={{ flex: "0 0 auto", alignSelf: "center" }}>
                    {serv.id}
                  </span>
                  <div className="pra-four-inner" style={{ flex: "0 0 auto" }}>
                    <h5>
                      <a href="#">{t(serv.title)}</a>
                    </h5>
                    <p>{t(serv.description)}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}
