import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import ReactFlagsSelect from "react-flags-select";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

const Header = () => {
  const { t } = useTranslation();
  const [select, setSelect] = useState("AE");
  const location = useLocation();
  const pathnameParts = location.pathname.split("/");
  const pageName = pathnameParts[pathnameParts.length - 1];
  const onSelect = (code) => {
    setSelect(code);
    if (code == "AE") {
      i18next.changeLanguage("ar");
    } else {
      i18next.changeLanguage("en");
    }
  };

  const [show, setShow] = React.useState(false);
  const menuClassName = `navbar-toggler toggleBar position-relative ${
    i18next.language === "ar" ? "left" : "right"
  }`;

  return (
    <>
      <header
        id="header"
        className="bg-white"
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          zIndex: 1000, // Adjust z-index as needed
        }}
      >
        <div
          className="sticky-wrap-menuHolder"
          style={{ backgroundColor: "white" }}
        >
          <div className="menuHolder">
            <div className="container-fluid">
              <nav className="nav-bg-white navbar navHolder d-flex navbar-expand-lg navbar-dark p-0 mt-lg-0 mr-lg-0">
                <div className="menuLogo">
                  <a href="/">
                    <img
                      src="images/header-logo.png"
                      alt="Auto &amp; Detailing template"
                      className="img-fluid logoBlack"
                    />
                  </a>
                </div>

                <div
                  className="collapse navbar-collapse navCollapse text-uppercase fwBlack"
                  id="nav-list"
                  style={{ justifyContent: "center" }}
                >
                  <ul className="navbar-nav">
                    <li className="nav-item custom-dropdown-box">
                      <a href="/" className={pageName == "" ? "active" : ""}>
                        {t("home")}
                      </a>
                    </li>
                    <li className="nav-item custom-dropdown-box">
                      <a
                        href="/#/services"
                        className={pageName == "services" ? "active" : ""}
                      >
                        {t("Services")}
                      </a>
                      <ul class="dropdown-child-manu">
                        <li>
                          <a href="/#/services">{t("sub-menu1")}</a>
                        </li>
                        <li>
                          <a href="/#/servicescompany">{t("sub-menu2")}</a>
                        </li>
                      </ul>
                    </li>

                    <li className="nav-item custom-dropdown-box">
                      <a
                        className={pageName == "about" ? "active" : ""}
                        href="/#/about"
                      >
                        {t("AboutHeading")}
                      </a>
                    </li>

                    <li className="nav-item custom-dropdown-box">
                      <a
                        className={pageName == "contact" ? "active" : ""}
                        href="/#/contact"
                      >
                        {t("contact-us")}
                      </a>
                    </li>
                  </ul>
                </div>

                <ReactFlagsSelect
                  onSelect={onSelect}
                  countries={["GB", "AE"]}
                  selected={select}
                  customLabels={{ GB: "English", AE: "عربي" }}
                  placeholder={t("language")}
                />
                <button
                  className={menuClassName}
                  type="button"
                  data-toggle="collapse"
                  data-target="#nav-list"
                  aria-controls="nav-list"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon">
                    <img src="images/menu.png" alt="Toggle Menu" />
                  </span>
                </button>
              </nav>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
