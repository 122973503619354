import { Icon } from "@iconify/react";
import React from "react";
import contact from "../constants/contact";

export default function AnimatedCall() {
  return (
    <>
      <a href={`tel:${contact.phone}`}>
        <div
          className="pulse"
          onClick={() => {
            // logEvent(analytics, "call_footer_button");
          }}
        >
          <Icon icon="solar:phone-bold" color="#fff" fontSize={30} />
        </div>
      </a>
    </>
  );
}
