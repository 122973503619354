const servicesind = [
  {
    id: 1,
    title: "service1title",
    description: "serviceind1desp",
    image: "images/practice/Abu-dhabi-police.jpg",
  },
  {
    id: 2,
    title: "service2title",
    description: "serviceind2desp",
    image: "images/practice/judicial-department.jpg",
  },
  {
    id: 3,
    title: "service3title",
    description: "serviceind3desp",
    image: "images/practice/economic-development.jpg",
  },
  {
    id: 4,
    title: "service4title",
    description: "serviceind4desp",
    image: "images/practice/translation.jpg",
  },
  {
    id: 5,
    title: "service5title",
    description: "serviceind5desp",
    image: "images/practice/vehical-insurance.jpg",
  },
  {
    id: 6,
    title: "service6title",
    description: "serviceind6desp",
    image: "images/practice/health-insurance.jpg",
  },
  {
    id: 7,
    title: "service7title",
    description: "serviceind7desp",
    image: "images/practice/transport-department.jpg",
  },
  {
    id: 8,
    title: "service8title",
    description: "serviceind8desp",
    image: "images/practice/Municipality.jpg",
  },
  {
    id: 9,
    title: "service9title",
    description: "serviceind9desp",
    image: "images/practice/Dubai-police.jpg",
  },
  {
    id: 10,
    title: "service10title",
    description: "serviceind10desp",
    image: "images/practice/Traffic-system.jpg",
  },
  {
    id: 11,
    title: "service11title",
    description: "serviceind11desp",
    image: "images/practice/passport.jpg",
  },
  {
    id: 12,
    title: "service12title",
    description: "serviceind12desp",
    image: "images/practice/Ministry-HR.jpg",
  },
  {
    id: 13,
    title: "service13title",
    description: "serviceind13desp",
    image: "images/practice/Tourist-visa.jpg",
  },
  {
    id: 14,
    title: "service14title",
    description: "serviceind14desp",
    image: "images/practice/worker.jpg",
  },
];
export default servicesind;
