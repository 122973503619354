import React, { useEffect } from "react";
import contact from "../constants/contact";
import { useTranslation } from "react-i18next";
import AOS from "aos";
import "aos/dist/aos.css";

export default function Contact() {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    AOS.init();
    AOS.refresh();
    // Scroll to the top of the page on component mount
    window.scrollTo({ top: 0, behavior: "smooth" });

    // Optionally, add a listener for 'beforeunload' to scroll to top when the page is refreshed
    const onBeforeUnload = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };

    window.addEventListener("beforeunload", onBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, []);
  return (
    <>
      <section
        className="breadcrumb-part"
        style={{
          backgroundImage: "url(images/bg/contact-us.jpg)",
          paddingTop: "150px",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <div className="breadcrumb-title">
                <h1>{t("contact_us")}</h1>
              </div>
            </div>
          </div>
          <div className="breadcrumb-link">
            <ul className="flat-list">
              <li>
                <a href="#">{t("home")}</a>
              </li>
              <li>
                <a href="#"> {t("contact_us")}</a>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="contuct-bottom style-two">
        <div className="container">
          <div className="row justify-content-center">
            <h3 className="text-center mt-3" style={{ color: "#ff0000" }}>
              <span> {t("contact_subtitle")}</span>
            </h3>
            <div className="col-lg-6" data-aos="fade-right">
              <div className="con-bottom-inner">
                <p>
                  <span>{t("address")}:</span> {contact.location}
                </p>
                <p>
                  <span>{t("call_us")}:</span>
                  <span>
                    <a href="tel:026319446">{contact.phone} / </a>
                    <a href="tel:0555488747">{contact.phone2}</a>
                  </span>
                </p>
                <p>
                  <span>Whatsapp:</span>
                  <a href="tel: +971-026319446"> {contact.whatsapp}</a>
                </p>

                <p>
                  <span>{t("email")}: </span>{" "}
                  <a href="https://themeim.com/cdn-cgi/l/email-protection#deb0bfb3bb9ebbb3bfb7b2f0bdb1b3">
                    {" "}
                    <span
                      className="__cf_email__"
                      data-cfemail="224b4c444d625b4d5750514b56470c414d4f"
                    ></span>
                    bo7miduae@gmail.com
                  </a>
                </p>
                <div className="per-social">
                  <ul>
                    <li>
                      <a href="#">
                        <i className="fa fa-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-linkedin"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-6" data-aos="fade-left">
              <form
                className="con-page-form"
                action="https://themeim.com/demo/imgra/mail.php"
                id="contact-form"
                method="post"
              >
                <input
                  type="text"
                  name="name"
                  placeholder={`${t("name")} *`}
                  className="mar-r"
                />
                <input
                  type="text"
                  name="email"
                  placeholder={`${t("email")} *`}
                />
                <textarea
                  className="mt-3"
                  name="message"
                  placeholder={`${t("message")} *`}
                ></textarea>
                <button type="submit">{t("send_message")}</button>
              </form>
              <p className="form-messege"></p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
