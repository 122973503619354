import React, { useTransition, useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Autoplay } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/bundle";
import "swiper/css/autoplay";
import services from "../constants/services";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import AOS from "aos";
import "aos/dist/aos.css";

export default function Home() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  // const progressCircle = useRef(null);
  // const progressContent = useRef(null);

  // const onAutoplayTimeLeft = (s: any, time: any, progress: any) => {
  //   progressCircle.current?.style.setProperty("--progress", 1 - progress);
  //   progressContent.current?.textContent = `${Math.ceil(time / 1000)}s`;
  // };
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <>
      <section
        className="banner-3-part"
        style={{ marginTop: 40, paddingTop: "150px" }}
      >
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper: any) => console.log(swiper)}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          modules={[Autoplay, EffectFade]}
          //onAutoplayTimeLeft={onAutoplayTimeLeft}
          effect={"fade"}
        >
          <SwiperSlide>
            <div
              className="swiper-slide banner-3-item"
              style={{ backgroundImage: "url(images/bg/home-banner-1.jpg)" }}
            >
              <div className="container" data-aos="fade-up">
                <div className="row d-flex">
                  <div className="col-xl-7 col-md-6">
                    <div className="banner-caption text-left">
                      <h2
                        className="text-center"
                        style={{ color: "#fff", background: "#ff0000" }}
                      >
                        Most Trusted Typing Firm{" "}
                      </h2>
                      <p>
                        Receive immediate assistance with our qualified staff,
                        for visa, immigration and other types of formal
                        paperwork.
                      </p>
                      <a href="#">
                        {t("free")} {t("consultation")}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <div
              className="swiper-slide banner-3-item"
              style={{ backgroundImage: "url(images/bg/slider-5.jpg)" }}
            >
              <div className="container">
                <div className="row d-flex">
                  <div className="col-xl-7 col-md-6">
                    <div className="banner-caption text-left">
                      <h2
                        className="text-center"
                        style={{ color: "#fff", background: "#ff0000" }}
                      >
                        Services for individuals and companies both!
                      </h2>
                      <p>
                        The information desk you've been looking for is in
                        Tarsh!
                      </p>
                      <a href="#">
                        {t("free")} {t("consultation")}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>

        <div className="banner-overlay-form">
          <div className="container">
            <div className="row">
              <div className="banner-contact">
                <h2 className="brand-color">
                  <span className="secondary-color">{t("free")}</span>
                  {t("consultation")}
                </h2>
                <p></p>
                <form>
                  <input type="text" placeholder={t("name")} />
                  <input type="text" placeholder={t("email")} />
                  <input type="text" placeholder={t("phone")} />
                  <button className="btn-1">{t("book_now")}</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="practise-3-part section-p pad-bot-50">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <div className="section-head">
                <h2>Our Service</h2>
                <p>
                  Dummy text of the printing and typesetting industry. Lorem
                  Ipsum has been the industry's standard unknown printer took a
                  quis nostrud exercitatliquip.
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            {services.map((serv) => (
              <div
                style={{ cursor: "pointer" }}
                data-aos="fade-up"
                className="col-lg-4 col-sm-6"
                onClick={() => {
                  navigate("/servicedetail", {
                    state: { serv },
                  });
                }}
              >
                <div className="practise-3-item practice-5-item">
                  <div className="icon-box">
                    <img
                      src={serv.image}
                      alt=""
                      style={{
                        width: "120px",
                        height: "120px",
                        padding: "5px",
                        borderRadius: "100px",
                        border: "2px solid #f00",
                      }}
                    />
                  </div>
                  <h5>
                    <a
                      onClick={() => {
                        navigate("/servicedetail", {
                          state: { serv },
                        });
                      }}
                    >
                      {t(serv.title)}
                    </a>
                  </h5>
                  <p>→</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="about-3-part section-p">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <div className="section-head text-center">
                <h2>Why Us</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-lg-7 col-xl-6" data-aos="fade-right">
              <div id="accordion" className="about-accodian">
                <div className="accodian-item">
                  <div className="accodian-head active d-flex align-items-center">
                    <h5>
                      <a
                        className=""
                        href="#"
                        data-toggle="collapse"
                        data-target="#collapseOne"
                        aria-expanded="false"
                        aria-controls="collapseOne"
                      >
                        {t("AboutHeading")}
                      </a>
                    </h5>
                  </div>
                  <div
                    id="collapseOne"
                    className="accodian-result collapse show"
                    data-parent="#accordion"
                  >
                    <p>{t("about-p1")}</p>
                  </div>
                </div>
                <div className="accodian-item">
                  <div className="accodian-head d-flex align-items-center">
                    <h5>
                      <a
                        className="collapsed"
                        href="#"
                        data-toggle="collapse"
                        data-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        {t("about-p5")}
                      </a>
                    </h5>
                  </div>
                  <div
                    id="collapseTwo"
                    className="accodian-result collapse"
                    data-parent="#accordion"
                  >
                    <p>{t("about-p6")}</p>
                  </div>
                </div>
              </div>
            </div>
            <div data-aos="fade-left" className="col-md-12 col-lg-5  col-xl-6">
              <div className="about-3">
                <div className="about-3-1">
                  <div data-relative-input="true" id="about-3-1">
                    <div data-depth="0.4">
                      <img src="images/about-3-1.jpg" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="col-12 text-center">
            <div className="section-head">
              <h2>Locate Us</h2>
              <p>
                Dummy text of the printing and typesetting industry. Lorem Ipsum
                has been the industry's standard unknown printer took a quis
                nostrud exercitatliquip.
              </p>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12" data-aos="fade-up">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7269.803749566459!2d54.48227399010443!3d24.349910537545963!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5e40f2404955f3%3A0x1e49a402279d3f6b!2sMusaffah%20-%20M-40%20-%20Abu%20Dhabi%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2sin!4v1711647351792!5m2!1sen!2sin"
                width="100%"
                height="450"
                allowFullScreen
                loading="lazy"
              ></iframe>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
