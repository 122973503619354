import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import AOS from "aos";
import "aos/dist/aos.css";

export default function About() {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    AOS.init();
    AOS.refresh();
    // Scroll to the top of the page on component mount
    window.scrollTo({ top: 0, behavior: "smooth" });

    // Optionally, add a listener for 'beforeunload' to scroll to top when the page is refreshed
    const onBeforeUnload = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };

    window.addEventListener("beforeunload", onBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, []);

  return (
    <>
      <section
        className="breadcrumb-part"
        style={{
          backgroundImage: "url(images/bg/about-us.jpg)",
          paddingTop: "150px",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <div className="breadcrumb-title">
                <h1>{t("about_us")}</h1>
              </div>
            </div>
          </div>
          <div className="breadcrumb-link">
            <ul className="flat-list">
              <li>
                <a href="#">{t("home")}</a>
              </li>
              <li>
                <a href="#">{t("about_us")}</a>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <div className="about-tab section-p">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <div className="section-head-3-1">
                <h2>
                  {t("tarsh")}
                  <span> {t("typing_center")}</span>
                </h2>
              </div>
            </div>
          </div>
          <div className="row about-tab-inner ">
            <div className="col-lg-7 col-xl-7">
              <div className="tab-content" id="nav-tabContent">
                <p>{t("about-p1")}</p>
                <br></br>
                <p>{t("about-p2")}</p>
                <br></br>
                <p>{t("about-p3")}</p>
                <br></br>
                <p>{t("about-p4")}</p>
                <br></br> <br></br>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="about-3">
                <div className="about-3-1">
                  <div data-relative-input="true" id="about-3-1">
                    <div data-depth="0.4">
                      <img src="images/about-3-1.jpg" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row about-tab-inner ">
            <div className="col-lg-5">
              <div className="about-3">
                <div className="about-3-1">
                  <div data-relative-input="true" id="about-3-1">
                    <div data-depth="0.4">
                      <img src="images/about-3-1.jpg" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-xl-7">
              <div className="tab-content" id="nav-tabContent">
                <h2>{t("about-p5")}</h2>
                <br></br>
                <p>{t("about-p6")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="counter-3-part section-p">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <div className="section-head light">
                <h2>Our Experience</h2>
                <p>
                  Dummy text of the printing and typesetting industry. Lorem
                  Ipsum has been the industry's standard unknown printer took a
                  quis nostrud exercitatliquip.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 col-6 text-center">
              <div className="counter-3-item">
                <div className="number-box">
                  <i className="flaticon-people"></i>
                </div>
                <h2 className="white counter">125</h2>
                <h3 className="font-size-16">Satisfied Clients</h3>
              </div>
            </div>

            <div className="col-md-3 col-6 text-center">
              <div className="counter-3-item">
                <div className="number-box">
                  <i className="flaticon-title"></i>
                  <h2 className="white counter">25</h2>
                </div>
                <h3 className="font-size-16">Year Experience</h3>
              </div>
            </div>

            <div className="col-md-3 col-6 mt-4 mt-md-0 text-center">
              <div className="counter-3-item">
                <div className="number-box">
                  <i className="flaticon-briefing"></i>
                  <h2 className="white counter">925</h2>
                </div>
                <h3 className="font-size-16">Project Done</h3>
              </div>
            </div>

            <div className="col-md-3 col-6 mt-4 mt-md-0 text-center">
              <div className="counter-3-item">
                <div className="number-box">
                  <i className="flaticon-boy-broad-smile"></i>
                  <h2 className="white counter">725</h2>
                </div>
                <h3 className="font-size-16">Happy Clients</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
